import React, { useState } from 'react';
import { DEFAULT_CONFIG } from '../constants/gameConfig';

function ConfigScreen({ onStartGame }) {
  const [config, setConfig] = useState(DEFAULT_CONFIG);

  const handleChange = (section, key, value) => {
    setConfig(prev => ({
      ...prev,
      [section]: {
        ...prev[section],
        [key]: value
      }
    }));
  };

  const handleBasicChange = (key, value) => {
    setConfig(prev => ({
      ...prev,
      [key]: value
    }));
  };

  // Convertir frames a ms
  const framesToMs = (frames) => Math.round(frames * 16.67);
  
  // Convertir ms a frames
  const msToFrames = (ms) => Number((ms / 16.67).toFixed(1));

  return (
    <div className="config-screen">
      <h2>Game Configuration</h2>

      <div className="config-section">
        <h3>Board Settings</h3>
        <div className="config-option">
          <label>
            Board Width:
            <input
              type="number"
              min="4"
              max="20"
              value={config.boardWidth}
              onChange={(e) => handleBasicChange('boardWidth', parseInt(e.target.value))}
            />
          </label>
        </div>
        <div className="config-option">
          <label>
            Board Height:
            <input
              type="number"
              min="10"
              max="40"
              value={config.boardHeight}
              onChange={(e) => handleBasicChange('boardHeight', parseInt(e.target.value))}
            />
          </label>
        </div>
        <div className="config-option">
          <label>
            Initial Speed (ms):
            <input
              type="number"
              min="100"
              max="2000"
              step="100"
              value={config.initialSpeed}
              onChange={(e) => handleBasicChange('initialSpeed', parseInt(e.target.value))}
            />
          </label>
        </div>
      </div>

      <div className="config-section">
        <h3>Features</h3>
        <div className="config-option">
          <label>
            <input
              type="checkbox"
              checked={config.ghostPieceEnabled}
              onChange={(e) => handleBasicChange('ghostPieceEnabled', e.target.checked)}
            />
            Enable Ghost Piece
          </label>
        </div>
        <div className="config-option">
          <label>
            <input
              type="checkbox"
              checked={config.holdEnabled}
              onChange={(e) => handleBasicChange('holdEnabled', e.target.checked)}
            />
            Enable Hold Piece
          </label>
        </div>
      </div>

      <div className="config-section">
        <h3>Handling</h3>
        <div className="config-option">
          <label>
            Auto Repeat Rate (ARR):
            <div className="slider-container">
              <span className="slider-label">SLOW</span>
              <input
                type="range"
                min="0"
                max="50"
                step="1"
                value={50 - msToFrames(config.handling.arr) * 10}
                onChange={(e) => {
                  const frames = Number(((50 - parseInt(e.target.value)) / 10).toFixed(1));
                  handleChange('handling', 'arr', framesToMs(frames));
                }}
              />
              <span className="slider-label">FAST</span>
            </div>
            <span className="value-display">{msToFrames(config.handling.arr)}F ({config.handling.arr}ms)</span>
          </label>
        </div>
        <div className="config-option">
          <label>
            Delayed Auto Shift (DAS):
            <div className="slider-container">
              <span className="slider-label">SLOW</span>
              <input
                type="range"
                min="10"
                max="200"
                step="1"
                value={200 - msToFrames(config.handling.das) * 10}
                onChange={(e) => {
                  const frames = Number(((200 - parseInt(e.target.value)) / 10).toFixed(1));
                  handleChange('handling', 'das', framesToMs(frames));
                }}
              />
              <span className="slider-label">FAST</span>
            </div>
            <span className="value-display">{msToFrames(config.handling.das)}F ({config.handling.das}ms)</span>
          </label>
        </div>
        <div className="config-option">
          <label>
            Drop Cancel Delay (DCD):
            <div className="slider-container">
              <span className="slider-label">SLOW</span>
              <input
                type="range"
                min="0"
                max="200"
                step="1"
                value={200 - msToFrames(config.handling.dcd) * 10}
                onChange={(e) => {
                  const frames = Number(((200 - parseInt(e.target.value)) / 10).toFixed(1));
                  handleChange('handling', 'dcd', framesToMs(frames));
                }}
              />
              <span className="slider-label">FAST</span>
            </div>
            <span className="value-display">{msToFrames(config.handling.dcd)}F ({config.handling.dcd}ms)</span>
          </label>
        </div>
        <div className="config-option">
          <label>
            Soft Drop Factor (SDF):
            <div className="slider-container">
              <span className="slider-label">SLOW</span>
              <input
                type="range"
                min="5"
                max="41"
                step="1"
                value={config.handling.sdf}
                onChange={(e) => {
                  const value = parseInt(e.target.value);
                  handleChange('handling', 'sdf', value);
                }}
              />
              <span className="slider-label">FAST</span>
            </div>
            <span className="value-display">{config.handling.sdf === 41 ? '∞' : config.handling.sdf}x</span>
          </label>
        </div>
      </div>

      <div className="config-section">
        <h3>Controls</h3>
        {Object.entries(config.controls).map(([action, key]) => (
          <div key={action} className="config-option">
            <label>
              {action.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}:
              <input
                type="text"
                value={key}
                onKeyDown={(e) => {
                  e.preventDefault();
                  handleChange('controls', action, e.key);
                }}
                readOnly
              />
            </label>
          </div>
        ))}
      </div>

      <button className="start-button" onClick={() => onStartGame(config)}>
        Start Game
      </button>
    </div>
  );
}

export default ConfigScreen;
