import React from 'react';
import { COLORS, SHAPES } from '../constants/tetrominos';

const PiecePreview = ({ piece, title }) => {
  if (!piece) return null;

  return (
    <div className="piece-preview">
      <h3>{title}</h3>
      <div className="preview-piece">
        {SHAPES[piece].map((row, i) => (
          <div key={i} className="preview-row">
            {row.map((cell, j) => (
              <div
                key={j}
                className={`preview-cell ${cell ? 'filled' : ''}`}
                style={{ backgroundColor: cell ? COLORS[piece] : 'transparent' }}
              />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PiecePreview;
