import React, { useEffect, useRef } from 'react';
import { COLORS } from '../constants/tetrominos';

const GameBoard = ({ board, currentPiece, ghostPiece, config }) => {
  const canvasRef = useRef(null);
  const { boardWidth, boardHeight, blockSize, spawnAreaRows } = config;

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    
    // Clear canvas
    ctx.fillStyle = COLORS.background;
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    
    // Draw grid
    ctx.strokeStyle = COLORS.grid;
    for (let x = 0; x < boardWidth; x++) {
      for (let y = 0; y < boardHeight; y++) {
        ctx.strokeRect(x * blockSize, y * blockSize, blockSize, blockSize);
      }
    }
    
    // Draw spawn area background
    ctx.fillStyle = '#87CEEB'; // Azul claro para el área de spawn
    ctx.fillRect(0, 0, boardWidth * blockSize, spawnAreaRows * blockSize);
    
    // Draw board
    board.forEach((row, y) => {
      row.forEach((value, x) => {
        if (value) {
          ctx.fillStyle = COLORS[value];
          ctx.fillRect(x * blockSize, y * blockSize, blockSize, blockSize);
        }
      });
    });
    
    // Draw ghost piece
    if (ghostPiece && config.ghostPieceEnabled) {
      ctx.fillStyle = COLORS.ghost;
      ghostPiece.shape.forEach((row, y) => {
        row.forEach((value, x) => {
          if (value) {
            ctx.fillRect(
              (ghostPiece.x + x) * blockSize,
              (ghostPiece.y + y) * blockSize,
              blockSize,
              blockSize
            );
          }
        });
      });
    }
    
    // Draw current piece
    if (currentPiece) {
      ctx.fillStyle = COLORS[currentPiece.type];
      currentPiece.shape.forEach((row, y) => {
        row.forEach((value, x) => {
          if (value) {
            ctx.fillRect(
              (currentPiece.x + x) * blockSize,
              (currentPiece.y + y) * blockSize,
              blockSize,
              blockSize
            );
          }
        });
      });
    }
  }, [board, currentPiece, ghostPiece, config]);

  return (
    <canvas
      ref={canvasRef}
      width={boardWidth * blockSize}
      height={boardHeight * blockSize}
      className="game-board"
    />
  );
};

export default GameBoard;
