export const DEFAULT_CONFIG = {
  // Board settings
  boardWidth: 10,
  boardHeight: 22,  // Aumentado de 20 a 22 para incluir el área de spawn
  blockSize: 30,
  initialSpeed: 800, // Initial drop speed in ms
  speedIncrease: 50,  // How much faster it gets per level
  linesPerLevel: 10,  // Lines needed to increase level
  nextPiecesCount: 6,  // Number of next pieces to show
  
  // Áreas visibles e invisibles
  visibleRows: 20,  // Filas visibles en el tablero
  spawnAreaRows: 2,  // Filas del área de spawn
  
  // Features
  ghostPieceEnabled: true,
  holdEnabled: true,
  
  // Controls
  controls: {
    moveLeft: 'j',
    moveRight: 'l',
    moveDown: 'k',
    rotate: 'i',
    rotateCounter: 's',
    rotate180: 'y',
    hardDrop: ' ',     // Space
    hold: 'c'
  },

  // Handling settings (in milliseconds)
  handling: {
    // Auto Repeat Rate (how fast pieces move when holding a direction)
    arr: 10, // 2F (33ms) - Jstris default
    
    // Delayed Auto Shift (initial delay before pieces start moving)
    das: 133, // 10F (167ms) - Jstris default
    
    // DAS Cut Delay (delay after canceling DAS when pressing drop or rotate)
    dcd: 0, // 10F (167ms) - Jstris default
    
    // Lock delays (Jstris standard)
    lockDelays: {
      L1: 500, // After softdrop
      L2: 5000, // While moving
      L3: 20000 // Maximum auto-lock time
    },
    
    // Soft Drop Factor (how much faster pieces fall when soft dropping)
    sdf: 41 // Default 20x, range: 5-40, 41 for infinite
  }
};
