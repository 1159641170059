import React, { useState, useCallback, useEffect } from 'react';
import './App.css';
import { SHAPES } from './constants/tetrominos';
import { DEFAULT_CONFIG } from './constants/gameConfig';
import GameBoard from './components/GameBoard';
import PiecePreview from './components/PiecePreview';
import ConfigScreen from './components/ConfigScreen';
import GameStats from './components/GameStats';
import { useGameLoop } from './hooks/useGameLoop';

function App() {
  const [gameStarted, setGameStarted] = useState(false);
  const [config, setConfig] = useState(DEFAULT_CONFIG);
  const [board, setBoard] = useState(null);
  const [currentPiece, setCurrentPiece] = useState(null);
  const [nextPieces, setNextPieces] = useState([]);
  const [holdPiece, setHoldPiece] = useState(null);
  const [canHold, setCanHold] = useState(true);
  const [gameOver, setGameOver] = useState(false);
  const [score, setScore] = useState(0);
  const [level, setLevel] = useState(1);
  const [lines, setLines] = useState(0);
  const [gameSpeed, setGameSpeed] = useState(config.initialSpeed);
  const [shouldPlacePiece, setShouldPlacePiece] = useState(false);

  // Movement timers and state
  const [moveDirection, setMoveDirection] = useState(0); // -1: left, 0: none, 1: right
  const [lastMoveTime, setLastMoveTime] = useState(0); // For horizontal movement
  const [lastFallTime, setLastFallTime] = useState(0); // For piece falling
  const [lastRotateTime, setLastRotateTime] = useState(0);
  const [dasStartTime, setDasStartTime] = useState(0);
  const [softDropping, setSoftDropping] = useState(false);

  // Lock delay
  const [lockState, setLockState] = useState({
    delay: config.handling.lockDelays.L2,
    timer: null,
    lastTouch: 0,
    lastMove: 0,
    lastRotate: 0
  });

  // Track pressed keys
  const [pressedKeys, setPressedKeys] = useState(new Set());

  // Create empty board
  const createEmptyBoard = useCallback((width, height) => {
    return Array(height).fill().map(() => Array(width).fill(0));
  }, []);

  // Generate pieces
  const generatePiece = useCallback(() => {
    const pieces = Object.keys(SHAPES);
    let newPiece;
    
    // Si hay al menos 2 piezas en nextPieces, evitar que la nueva sea igual a las últimas dos
    if (nextPieces.length >= 2) {
      const lastTwo = nextPieces.slice(0, 2).map(p => p);
      do {
        newPiece = pieces[Math.floor(Math.random() * pieces.length)];
      } while (lastTwo[0] === newPiece && lastTwo[1] === newPiece);
    } else {
      newPiece = pieces[Math.floor(Math.random() * pieces.length)];
    }
    
    return newPiece;
  }, [nextPieces]);

  // Create new piece
  const createPiece = useCallback((type, gameConfig) => {
    return {
      type,
      x: Math.floor(gameConfig.boardWidth / 2) - Math.floor(SHAPES[type][0].length / 2),
      y: 0,
      shape: SHAPES[type]
    };
  }, []);

  // Start new game
  const startGame = useCallback((newConfig) => {
    setConfig(newConfig);
    setBoard(createEmptyBoard(newConfig.boardWidth, newConfig.boardHeight));
    setNextPieces([]); // Empezar con array vacío
    setCurrentPiece(null);
    setHoldPiece(null);
    setCanHold(true);
    setGameOver(false);
    setScore(0);
    setLevel(1);
    setLines(0);
    setGameSpeed(newConfig.initialSpeed);
    
    // Generar las primeras 6 piezas
    const initialPieces = Array(6).fill().map(() => generatePiece());
    setNextPieces(initialPieces);
    setCurrentPiece(createPiece(initialPieces[0], newConfig));
    setNextPieces(initialPieces.slice(1));
    setGameStarted(true);
  }, [generatePiece, createPiece, createEmptyBoard]);

  // Get random piece ensuring no more than 2 consecutive pieces of the same type
  const getRandomPiece = useCallback(() => {
    const pieces = Object.values(SHAPES);
    const newPieces = [];
    
    for (let i = 0; i < config.nextPiecesCount; i++) {
      // Si es la primera pieza, generamos cualquier pieza
      if (i === 0) {
        newPieces.push(pieces[Math.floor(Math.random() * pieces.length)]);
        continue;
      }

      // Para las siguientes piezas, evitamos que haya 3 consecutivas iguales
      let randomIndex;
      do {
        randomIndex = Math.floor(Math.random() * pieces.length);
        // Si la nueva pieza es igual a las dos anteriores, generamos otra
      } while (newPieces.length >= 2 && 
               newPieces[newPieces.length - 1].type === pieces[randomIndex].type &&
               newPieces[newPieces.length - 2].type === pieces[randomIndex].type);

      newPieces.push(pieces[randomIndex]);
    }
    
    return newPieces;
  }, [config.nextPiecesCount]);

  // Ensure we always have 6 next pieces
  const ensureNextPieces = useCallback(() => {
    if (nextPieces.length < 6) {
      const piecesToAdd = 6 - nextPieces.length;
      const newPieces = [];
      
      for (let i = 0; i < piecesToAdd; i++) {
        // Si es la primera pieza, generamos cualquier pieza
        if (i === 0) {
          newPieces.push(generatePiece());
          continue;
        }

        // Para las siguientes piezas, evitamos que haya 3 consecutivas iguales
        let newPiece;
        do {
          newPiece = generatePiece();
          // Si la nueva pieza es igual a las dos anteriores, generamos otra
        } while (newPieces.length >= 2 && 
                 newPieces[newPieces.length - 1].type === newPiece.type &&
                 newPieces[newPieces.length - 2].type === newPiece.type);

        newPieces.push(newPiece);
      }
      
      setNextPieces(prev => [...prev, ...newPieces]);
    }
  }, [nextPieces, generatePiece]);

  // Create new piece
  const createNextPiece = useCallback(() => {
    if (nextPieces.length === 0) return null;
    
    const newPiece = createPiece(nextPieces[0], config);
    setNextPieces(prev => prev.slice(1));
    ensureNextPieces(); // Asegurarnos de mantener 6 piezas en cola
    
    return newPiece;
  }, [nextPieces, config, createPiece, ensureNextPieces]);

  // Verificar si un movimiento es válido
  const isValidMove = useCallback((shape, x, y, board) => {
    for (let row = 0; row < shape.length; row++) {
      for (let col = 0; col < shape[row].length; col++) {
        if (shape[row][col]) {
          // Verificar bordes del tablero
          if (x + col < 0 || x + col >= config.boardWidth || y + row >= config.boardHeight) {
            return false;
          }
          // Verificar colisiones con piezas existentes
          if (y + row >= 0 && board[y + row][x + col] !== 0) {
            return false;
          }
        }
      }
    }
    return true;
  }, [config.boardWidth, config.boardHeight]);

  // Check collision
  const isCollision = useCallback((piece, gameBoard) => {
    if (!piece) return false;
    
    for (let y = 0; y < piece.shape.length; y++) {
      for (let x = 0; x < piece.shape[y].length; x++) {
        if (piece.shape[y][x]) {
          const newX = piece.x + x;
          const newY = piece.y + y;
          
          if (
            newX < 0 || 
            newX >= config.boardWidth ||
            newY >= config.boardHeight ||
            (newY >= 0 && gameBoard[newY][newX])
          ) {
            return true;
          }
        }
      }
    }
    return false;
  }, [config.boardWidth, config.boardHeight]);

  // Get ghost piece position
  const getGhostPosition = useCallback(() => {
    if (!currentPiece || gameOver) return null;
    
    let dropY = currentPiece.y;
    while (!isCollision({ ...currentPiece, y: dropY + 1 }, board)) {
      dropY++;
    }
    
    return { ...currentPiece, y: dropY };
  }, [currentPiece, gameOver, board, isCollision]);

  // Función para calcular la posición de la ghost piece
  const calculateGhostPosition = useCallback(() => {
    if (!currentPiece) return null;
    
    let ghostY = currentPiece.y;
    while (isValidMove(currentPiece.shape, currentPiece.x, ghostY + 1, board)) {
      ghostY++;
    }
    return ghostY;
  }, [currentPiece, board, isValidMove]);

  // Move piece
  const movePiece = useCallback((dx, dy) => {
    if (!currentPiece || gameOver) return false;
    
    const newPiece = {
      ...currentPiece,
      x: currentPiece.x + dx,
      y: currentPiece.y + dy
    };

    if (!isCollision(newPiece, board)) {
      setCurrentPiece(newPiece);
      return true;
    }

    // Si hay colisión al mover hacia abajo, marcar para colocar la pieza
    if (dy > 0) {
      setShouldPlacePiece(true);
    }
    return false;
  }, [currentPiece, gameOver, board, isCollision]);

  // Rotate piece clockwise (Z)
  const rotatePiece = useCallback(() => {
    if (!currentPiece || gameOver) return false;
    
    // Rotar la pieza 90 grados en sentido de las agujas del reloj
    const newShape = currentPiece.shape.map((row, y) => 
      currentPiece.shape.map((_, x) => 
        currentPiece.shape[currentPiece.shape.length - 1 - x][y]
      )
    );

    // Verificar si la nueva posición es válida
    if (isValidMove(newShape, currentPiece.x, currentPiece.y, board)) {
      setCurrentPiece(prev => ({
        ...prev,
        shape: newShape
      }));
      return true;
    }

    // Intentar mover la pieza para que la rotación sea válida
    const positions = [
      { x: currentPiece.x, y: currentPiece.y },
      { x: currentPiece.x - 1, y: currentPiece.y },
      { x: currentPiece.x + 1, y: currentPiece.y },
      { x: currentPiece.x, y: currentPiece.y - 1 },
      { x: currentPiece.x, y: currentPiece.y + 1 }
    ];

    for (const pos of positions) {
      if (isValidMove(newShape, pos.x, pos.y, board)) {
        setCurrentPiece(prev => ({
          ...prev,
          shape: newShape,
          x: pos.x,
          y: pos.y
        }));
        return true;
      }
    }

    return false;
  }, [currentPiece, gameOver, board, isValidMove]);

  // Rotate piece counter-clockwise (S)
  const rotatePieceCounter = useCallback(() => {
    if (!currentPiece || gameOver) return false;
    
    // Rotar la pieza 90 grados en sentido contrario a las agujas del reloj
    const newShape = currentPiece.shape.map((row, y) => 
      currentPiece.shape.map((_, x) => 
        currentPiece.shape[x][currentPiece.shape.length - 1 - y]
      )
    );

    // Verificar si la nueva posición es válida
    if (isValidMove(newShape, currentPiece.x, currentPiece.y, board)) {
      setCurrentPiece(prev => ({
        ...prev,
        shape: newShape
      }));
      return true;
    }

    // Intentar mover la pieza para que la rotación sea válida
    const positions = [
      { x: currentPiece.x, y: currentPiece.y },
      { x: currentPiece.x - 1, y: currentPiece.y },
      { x: currentPiece.x + 1, y: currentPiece.y },
      { x: currentPiece.x, y: currentPiece.y - 1 },
      { x: currentPiece.x, y: currentPiece.y + 1 }
    ];

    for (const pos of positions) {
      if (isValidMove(newShape, pos.x, pos.y, board)) {
        setCurrentPiece(prev => ({
          ...prev,
          shape: newShape,
          x: pos.x,
          y: pos.y
        }));
        return true;
      }
    }

    return false;
  }, [currentPiece, gameOver, board, isValidMove]);

  // Rotate piece 180 degrees (Y)
  const rotatePiece180 = useCallback(() => {
    if (!currentPiece || gameOver) return false;
    
    // Rotar la pieza 180 grados
    const newShape = currentPiece.shape.map((row, y) => 
      currentPiece.shape.map((_, x) => 
        currentPiece.shape[currentPiece.shape.length - 1 - y][currentPiece.shape.length - 1 - x]
      )
    );

    // Verificar si la nueva posición es válida
    if (isValidMove(newShape, currentPiece.x, currentPiece.y, board)) {
      setCurrentPiece(prev => ({
        ...prev,
        shape: newShape
      }));
      return true;
    }

    // Intentar mover la pieza para que la rotación sea válida
    const positions = [
      { x: currentPiece.x, y: currentPiece.y },
      { x: currentPiece.x - 1, y: currentPiece.y },
      { x: currentPiece.x + 1, y: currentPiece.y },
      { x: currentPiece.x, y: currentPiece.y - 1 },
      { x: currentPiece.x, y: currentPiece.y + 1 }
    ];

    for (const pos of positions) {
      if (isValidMove(newShape, pos.x, pos.y, board)) {
        setCurrentPiece(prev => ({
          ...prev,
          shape: newShape,
          x: pos.x,
          y: pos.y
        }));
        return true;
      }
    }

    return false;
  }, [currentPiece, gameOver, board, isValidMove]);

  // Game state updates
  const updateGameState = useCallback((newBoard, clearedLines) => {
    setBoard(newBoard);
    setCanHold(true);
    
    if (clearedLines > 0) {
      setLines(prev => {
        const newTotal = prev + clearedLines;
        const newLevel = Math.floor(newTotal / config.linesPerLevel) + 1;
        if (newLevel > level) {
          setLevel(newLevel);
          setGameSpeed(Math.max(config.initialSpeed - (newLevel - 1) * config.speedIncrease, 100));
        }
        return newTotal;
      });
      setScore(prev => prev + (clearedLines * 100 * level));
    }
  }, [config.linesPerLevel, config.initialSpeed, config.speedIncrease, level]);

  // Clear completed lines
  const clearLines = useCallback((gameBoard) => {
    const newBoard = gameBoard.filter(row => row.includes(0));
    const clearedLines = config.boardHeight - newBoard.length;
    
    if (clearedLines > 0) {
      const newRows = Array(clearedLines).fill().map(() => Array(config.boardWidth).fill(0));
      return [...newRows, ...newBoard];
    }
    return gameBoard;
  }, [config.boardHeight, config.boardWidth]);

  // Place piece and handle game logic
  const placePiece = useCallback(() => {
    if (!currentPiece) return;
    
    // Place the piece on the board
    const newBoard = [...board];
    currentPiece.shape.forEach((row, y) => {
      row.forEach((value, x) => {
        if (value) {
          const boardY = currentPiece.y + y;
          const boardX = currentPiece.x + x;
          if (boardY >= 0) {
            newBoard[boardY][boardX] = currentPiece.type;
          }
        }
      });
    });
    
    // Clear lines and update game state
    const clearedBoard = clearLines(newBoard);
    const clearedLines = newBoard.length - clearedBoard.length;
    updateGameState(clearedBoard, clearedLines);
    
    // Create next piece
    const nextPiece = createNextPiece();
    if (nextPiece) {
      if (isCollision(nextPiece, clearedBoard)) {
        setGameOver(true);
      } else {
        setCurrentPiece(nextPiece);
      }
    }
  }, [currentPiece, board, clearLines, updateGameState, createNextPiece, isCollision]);

  // Hard drop
  const hardDrop = useCallback(() => {
    if (!currentPiece || gameOver) return;
    
    const ghostY = calculateGhostPosition();
    if (ghostY !== null) {
      setCurrentPiece({ ...currentPiece, y: ghostY });
      placePiece();
    }
  }, [currentPiece, gameOver, calculateGhostPosition, placePiece]);

  // Hold piece
  const holdCurrentPiece = useCallback(() => {
    if (!currentPiece || !canHold || gameOver || !config.holdEnabled) return;

    const pieceToHold = currentPiece.type;
    
    if (holdPiece) {
      const newPiece = createPiece(holdPiece, config);
      setCurrentPiece(newPiece);
    } else {
      if (nextPieces.length > 0) {
        const newPiece = createPiece(nextPieces[0], config);
        setCurrentPiece(newPiece);
        setNextPieces(prev => prev.slice(1));
      }
    }
    
    setHoldPiece(pieceToHold);
    setCanHold(false);
  }, [currentPiece, canHold, gameOver, config, holdPiece, nextPieces, createPiece]);

  // Verificar si la pieza está tocando el suelo
  const isTouchingGround = useCallback(() => {
    if (!currentPiece) return false;
    return !isValidMove(currentPiece.shape, currentPiece.x, currentPiece.y + 1, board);
  }, [currentPiece, board]);

  // Resetear el lock delay
  const resetLockDelay = useCallback(() => {
    setLockState(prev => ({
      ...prev,
      delay: config.handling.lockDelays.L2,
      timer: null,
      lastTouch: Date.now()
    }));
    setShouldPlacePiece(false);
  }, [config]);

  // Iniciar el lock delay
  const startLockDelay = useCallback(() => {
    const now = Date.now();
    const { lockDelays } = config.handling;
    
    // Si ya hay un timer activo, no hacer nada
    if (lockState.timer) return;
  
    let delay;
    if (softDropping) {
      delay = lockDelays.L1;
    } else if (moveDirection !== 0 || now - lockState.lastMove < lockDelays.L2) {
      delay = lockDelays.L2;
    } else {
      delay = lockDelays.L3;
    }
  
    setLockState(prev => ({
      ...prev,
      delay,
      timer: now, // Usamos now como referencia
      lastTouch: now
    }));
  }, [softDropping, moveDirection, lockState, config.handling]);

  // Check if piece should lock
  const checkLockDelay = useCallback(() => {
    const now = Date.now();
    
    if (lockState.timer && now - lockState.timer >= lockState.delay) {
      console.log('Locking piece after', lockState.delay, 'ms');
      setShouldPlacePiece(true);
      setLockState(prev => ({
        ...prev,
        timer: null,
        lastTouch: now
      }));
    }
  }, [lockState, setShouldPlacePiece]);

  // Handle key down
  const handleKeyDown = useCallback((e) => {
    if (gameOver || !gameStarted) return;

    const now = Date.now();
    const { controls } = config;

    // Add key to pressed keys
    setPressedKeys(prev => {
      const newSet = new Set(prev);
      newSet.add(e.key);
      return newSet;
    });

    switch(e.key) {
      case controls.moveLeft:
      case controls.moveRight: {
        const direction = e.key === controls.moveLeft ? -1 : 1;
        
        if (moveDirection !== direction) {
          setMoveDirection(direction);
          setDasStartTime(now);
          setLastMoveTime(now);
          
          if (movePiece(direction, 0)) {
            setLockState(prev => ({
              ...prev,
              lastMove: now,
              lastRotate: now
            }));
            resetLockDelay();
          }
        }
        break;
      }
      case controls.moveDown:
        setSoftDropping(true);
        break;
      case controls.rotate:
      case controls.rotateCounter:
      case controls.rotate180:
        const timeSinceLastRotate = now - lastRotateTime;
        if (timeSinceLastRotate >= config.handling.dcd) {
          if (rotatePiece()) {
            setLockState(prev => ({
              ...prev,
              lastRotate: now
            }));
            resetLockDelay();
            setLastRotateTime(now);
          }
        }
        break;
      case controls.hardDrop:
        const ghost = getGhostPosition();
        if (ghost) {
          setCurrentPiece(ghost);
          setShouldPlacePiece(true);
        }
        break;
      case controls.hold:
        if (holdCurrentPiece()) {
          resetLockDelay();
        }
        break;
      default:
        break;
    }
  }, [gameOver, gameStarted, moveDirection, dasStartTime, lastMoveTime, lastRotateTime, config, movePiece, softDropping, rotatePiece, hardDrop, holdCurrentPiece, resetLockDelay, getGhostPosition, currentPiece, setCurrentPiece, setShouldPlacePiece]);

  // Handle key up
  const handleKeyUp = useCallback((e) => {
    const { controls } = config;

    // Remove key from pressed keys
    setPressedKeys(prev => {
      const newSet = new Set(prev);
      newSet.delete(e.key);
      return newSet;
    });

    switch(e.key) {
      case controls.moveLeft:
        if (moveDirection === -1) {
          setMoveDirection(0);
          setDasStartTime(0);
        }
        break;
      case controls.moveRight:
        if (moveDirection === 1) {
          setMoveDirection(0);
          setDasStartTime(0);
        }
        break;
      case controls.moveDown:
        setSoftDropping(false);
        break;
      default:
        break;
    }
  }, [moveDirection, config]);

  // Game loop for continuous movement and piece falling
  useEffect(() => {
    if (gameOver || !gameStarted) return;

    const handleMovement = () => {
      const now = Date.now();
      const { controls } = config;

      // Check if any movement keys are pressed
      const isLeftPressed = pressedKeys.has(controls.moveLeft);
      const isRightPressed = pressedKeys.has(controls.moveRight);

      // Set move direction based on pressed keys
      if (isLeftPressed && isRightPressed) {
        setMoveDirection(0);
      } else if (isLeftPressed) {
        setMoveDirection(-1);
      } else if (isRightPressed) {
        setMoveDirection(1);
      } else {
        setMoveDirection(0);
      }

      // Handle horizontal movement (DAS/ARR)
      if (moveDirection !== 0) {
        const timeSinceDasStart = now - dasStartTime;
        const timeSinceLastMove = now - lastMoveTime;
        const timeSinceLastAction = now - lastMoveTime;

        if (timeSinceLastAction < config.handling.dcd) {
          return;
        }

        if (timeSinceDasStart >= config.handling.das && 
            timeSinceLastMove >= config.handling.arr) {
          if (movePiece(moveDirection, 0)) {
            setLockState(prev => ({
              ...prev,
              lastMove: now,
              lastRotate: now
            }));
            resetLockDelay();
            setLastMoveTime(now);
          }
        }
      }

if (softDropping) {
  if (config.handling.sdf === 41) {
    const ghost = getGhostPosition();
    if (ghost && ghost.y !== currentPiece.y) {
      setCurrentPiece(ghost);
      resetLockDelay();if (softDropping) {
        if (config.handling.sdf === 41) {
          const ghost = getGhostPosition();
          if (ghost && ghost.y !== currentPiece.y) {
            setCurrentPiece(ghost);
            resetLockDelay();
            setLastMoveTime(now);
          }
        } else {
          const dropInterval = Math.floor(1000 / config.handling.sdf);
          const timeSinceLastMove = now - lastMoveTime;
          if (timeSinceLastMove >= dropInterval) {
            if (movePiece(0, 1)) {
              setLastMoveTime(now);
              resetLockDelay();
            } else if (isTouchingGround()) {
              if (!lockState.timer) {
                startLockDelay();
              }
              checkLockDelay();
            }
          }
        }
      }
      setLastMoveTime(now); 
    }
  } else {
    // Soft drop normal con lock delay
    const dropInterval = Math.floor(1000 / config.handling.sdf);
    const timeSinceLastMove = now - lastMoveTime;
  
    if (timeSinceLastMove >= dropInterval) {
      if (movePiece(0, 1)) {
        setLastMoveTime(now);
        resetLockDelay();
      } else if (isTouchingGround()) {
        // Si la pieza no se puede mover hacia abajo y está tocando el suelo,
        // se inicia el lock delay habitual
        if (!lockState.timer) {
          startLockDelay();
        }
        checkLockDelay();
      }
    }
  }
}



      // Normal piece falling
      if (!softDropping && now - lastMoveTime >= gameSpeed) {
        if (movePiece(0, 1)) {
          setLastMoveTime(now);
          resetLockDelay();
        } else if (isTouchingGround()) {
          // Solo inicia el lock delay si no está activo
          if (!lockState.timer) {
            startLockDelay();
          }
          checkLockDelay();
        }
      }

      // Check if piece should lock
      checkLockDelay();
    };

    const gameLoop = setInterval(handleMovement, 16);
    return () => {
      clearInterval(gameLoop);
      if (lockState.timer) {
        clearTimeout(lockState.timer);
      }
    };
  }, [gameOver, gameStarted, moveDirection, dasStartTime, lastMoveTime, softDropping, config.handling, gameSpeed, movePiece, resetLockDelay, startLockDelay, lockState, isTouchingGround, pressedKeys, lastRotateTime, getGhostPosition, currentPiece, setCurrentPiece, setShouldPlacePiece]);

  // Cleanup al desmontar
  useEffect(() => {
    return () => {
      if (lockState.timer) {
        clearTimeout(lockState.timer);
      }
    };
  }, [lockState]);

  // Effect to handle piece placement
  useEffect(() => {
    if (shouldPlacePiece && currentPiece) {
      placePiece();
      setShouldPlacePiece(false);
    }
  }, [shouldPlacePiece, currentPiece, placePiece]);

  // Effect for keyboard controls
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [handleKeyDown, handleKeyUp]);

  if (!gameStarted) {
    return <ConfigScreen onStartGame={startGame} />;
  }

  return (
    <div className="App">
      <div className="game-container">
        <div className="side-panel">
          {config.holdEnabled && (
            <PiecePreview piece={holdPiece} title="Hold" />
          )}
          <GameStats score={score} level={level} lines={lines} />
        </div>
        
        <GameBoard
          board={board}
          currentPiece={currentPiece}
          ghostPiece={getGhostPosition()}
          config={config}
        />
        
        <div className="side-panel">
          <div className="next-pieces">
            <h3>Next</h3>
            {nextPieces.slice(0, 6).map((piece, index) => (
              <PiecePreview key={index} piece={piece} />
            ))}
          </div>
        </div>
      </div>
      
      {gameOver && (
        <div className="game-over">
          <h2>Game Over!</h2>
          <p>Final Score: {score}</p>
          <button onClick={() => startGame(config)}>Play Again</button>
          <button onClick={() => setGameStarted(false)}>Change Configuration</button>
        </div>
      )}
    </div>
  );
}

export default App;
