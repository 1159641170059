import React from 'react';

const GameStats = ({ score, level, lines }) => {
  return (
    <div className="game-stats">
      <div className="stat-item">
        <label>Score:</label>
        <span>{score}</span>
      </div>
      <div className="stat-item">
        <label>Level:</label>
        <span>{level}</span>
      </div>
      <div className="stat-item">
        <label>Lines:</label>
        <span>{lines}</span>
      </div>
    </div>
  );
};

export default GameStats;
